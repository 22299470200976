body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

p {
    margin: 0;
}

.message-mine {
    background-attachment: fixed;
    position             : relative;
    align-self: flex-end;
    margin: 2px 10px;
    max-width: 80%;
    word-break: break-all;
    border-radius: 10px;
    padding: 10px;
    transform-style: preserve-3d;
}

.message-mine p {
    margin: 0;
    z-index: 50;
}

.message-yours p {
    margin: 0;
    z-index: 50;
}

p.timestamp {
    margin: 0; 
    font-size: 0.8rem; 
    opacity: 0.5;
}

.message-mine.end:before {
    content                  : "";
    position                 : absolute;
    z-index                  : 0;
    bottom                   : 0;
    right                    : -8px;
    height                   : 20px;
    width                    : 35px;
    background               : inherit;
    background-attachment    : fixed;
    border-bottom-left-radius: 15px;
    transform: translateZ(-1px);
}

.message-mine.end:after {
    content                  : "";
    position                 : absolute;
    z-index                  : 3;
    bottom                   : 0;
    right                    : -10px;
    width                    : 10px;
    height                   : 20px;
    background-color         : var(--background-default);
    border-bottom-left-radius: 10px;
}

.message-yours {
    position: relative;
    align-self: flex-start;
    margin: 2px 10px;
    max-width: 80%;
    word-break: break-all;
    border-radius: 10px;
    padding: 10px;
    transform-style: preserve-3d;
}

.message-yours.end:before {
    content                   : "";
    position                  : absolute;
    z-index                   : 0;
    bottom                    : 0;
    left                      : -7px;
    height                    : 20px;
    width                     : 35px;
    background                : inherit;
    border-bottom-right-radius: 15px;
    transform: translateZ(-1px);
}

.message-yours.end:after {
    content                   : "";
    position                  : absolute;
    z-index                   : 1;
    bottom                    : 0;
    left                      : -10px;
    width                     : 10px;
    height                    : 20px;
    background-color          : var(--background-default);
    border-bottom-right-radius: 10px;
}